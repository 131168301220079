import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/layout'
import CasesGrid from '../components/cases_grid'

const ArchiveKeywordPageTemplate = ({ data, pageContext }) => {
  return(
    <Layout pageTitle={"キーワード ⇢ “" + pageContext.keyword + "”"}>
      <CasesGrid data={data}></CasesGrid>
    </Layout>
  )
}

export const query = graphql`
  query ($keyword: String) {
    allAirtable(
      filter: {
        table: {eq: "Cases"},
        data: {Keywords: {elemMatch: {data: {Name: {eq: $keyword}}}}}
      }
      sort: {fields: data___Id, order: DESC}
    ) {
      nodes {
        data {
          Name
          Photos {
            localFiles {
              childImageSharp {
                gatsbyImageData(breakpoints: [300, 600, 900])
              }
            }
          }
          Taken_by
          Id
        }
      }
    }
  }
`

export default ArchiveKeywordPageTemplate